var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "abilitiestemplates")
    ? _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "24", xl: "12", lg: "12", md: "12" } },
                [
                  _c("abilities-templates-permissions-card", {
                    attrs: { "template-data": _vm.templateData }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }